import React from "react";

const LogoIcon = () => {
    return(
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595.28 158.67">
            <rect className="cls-3" x="73.39" y="31.24" width="110.95" height="110.95" rx="45.35" ry="45.35"/>
            <g>
                <g>
                    <path className="cls-2" d="m158.93,72.75l-9.32-8.49h0c.39.33.64.81.64,1.35v16.24c0,.13-.02.26-.04.38l.04,1.34h-19.17c-.95,0-1.72.77-1.72,1.72v16.78c0,.95.77,1.72,1.72,1.72h7.47v-11.03h19.17c.95,0,1.72-.77,1.72-1.72v-17.24c0-.46-.18-.89-.5-1.04Z"/>
                    <path className="cls-2" d="m149.56,105.49v16.24c0,.26-.06.5-.16.72l.16.99h-12.83c-9.38,0-17.65-4.8-22.51-12.06,6.9,11.2,20.89,21.25,31.69,21.25h11.11c.95,0,1.72-.77,1.72-1.72v-17.24c0-.46-.18-.89-.5-1.21l-9.3-8.29h-.01c.38.32.62.79.62,1.33Z"/>
                    <path className="cls-1" d="m150.24,65.61c0-.54-.25-1.02-.64-1.33-.3-.24-.67-.38-1.08-.38h-37.12c-.95,0-1.72.77-1.72,1.72v30.79c0,5.54,1.68,10.7,4.54,14.99,4.85,7.27,13.13,12.06,22.51,12.06h12.83v-17.95c0-.53-.24-1.01-.62-1.32-.3-.25-.68-.39-1.09-.39h-16.77c-.95,0-1.72-.77-1.72-1.72v-16.78c0-.95.77-1.72,1.72-1.72h19.17v-17.95Z"/>
                </g>
                <g>
                    <path className="cls-2" d="m128.54,41.01l-.02.03c.5.3.84.85.84,1.47v3.34c0,.58-.29,1.12-.78,1.44l-18.85,12.35h-.05l4.25,4.25h11.87l11.71-7.67c.48-.32.78-.86.78-1.44v-4.29c0-.49-.21-.95-.57-1.28l-9.17-8.2Z"/>
                    <path className="cls-1" d="m128.52,41.04c-.26-.15-.56-.25-.88-.25h-16.24c-.95,0-1.72.77-1.72,1.72v17.12h.05l18.85-12.35c.48-.32.78-.86.78-1.44v-3.34c0-.63-.34-1.17-.84-1.47Z"/>
                </g>
            </g>
            <g>
                <path className="cls-3" d="m296.04,65.32c-1.9-2.01-4.43-3.55-7.58-4.62-3.16-1.08-7.1-1.61-11.83-1.61-1.87,0-3.71.09-5.54.27-1.83.18-3.57.39-5.22.65-1.65.25-3.17.54-4.57.86-.73.17-1.4.33-2,.49-.85.22-1.39,1.06-1.25,1.93l1.49,9.41c.16,1.01,1.17,1.67,2.15,1.39,1.51-.43,3.19-.79,5.04-1.07,2.65-.39,5.34-.59,8.07-.59,4.09,0,6.95.83,8.6,2.47,1.65,1.65,2.47,3.84,2.47,6.56v1.51c-1-.29-2.31-.52-3.93-.7-1.61-.18-3.46-.27-5.54-.27-3.08,0-6.06.31-8.93.91-2.87.61-5.41,1.6-7.64,2.96-2.22,1.36-4,3.19-5.32,5.48-1.33,2.3-1.99,5.09-1.99,8.39s.57,6.34,1.72,8.71c1.15,2.37,2.8,4.28,4.95,5.75,2.15,1.47,4.71,2.53,7.69,3.17,2.97.65,6.29.97,9.95.97,5.81,0,10.75-.34,14.84-1.02,3.33-.55,6.06-1.05,8.2-1.49.8-.16,1.37-.87,1.37-1.68v-31.94c0-3.51-.39-6.7-1.18-9.57-.79-2.87-2.13-5.3-4.03-7.31Zm-10.16,40.22c-.86.14-2.01.25-3.44.32-1.44.07-2.94.11-4.52.11-2.94,0-5.25-.48-6.94-1.45-1.69-.97-2.53-2.71-2.53-5.22,0-1.29.29-2.37.86-3.23.57-.86,1.36-1.54,2.37-2.04,1-.5,2.17-.84,3.49-1.02,1.33-.18,2.74-.27,4.25-.27,1.08,0,2.26.07,3.55.22,1.29.14,2.26.29,2.9.43v12.15Z"/>
                <path className="cls-3" d="m413.69,37.31c-1.9-1.68-4.14-2.53-6.72-2.53s-4.82.84-6.72,2.53c-1.9,1.69-2.85,4-2.85,6.94s.95,5.25,2.85,6.94c1.9,1.69,4.14,2.53,6.72,2.53s4.82-.84,6.72-2.53c1.9-1.68,2.85-4,2.85-6.94s-.95-5.25-2.85-6.94Z"/>
                <path className="cls-3" d="m473.86,72.63c-.79-2.87-2.13-5.3-4.03-7.31-1.9-2.01-4.43-3.55-7.58-4.62-3.16-1.08-7.1-1.61-11.83-1.61-1.87,0-3.71.09-5.54.27-1.83.18-3.57.39-5.22.65-1.65.25-3.17.54-4.57.86-.43.1-.83.2-1.21.29-1.3.32-2.13,1.58-1.92,2.9l1.22,7.69c.24,1.5,1.71,2.47,3.17,2.09,1.27-.33,2.67-.6,4.17-.83,2.65-.39,5.34-.59,8.07-.59,4.09,0,6.95.83,8.6,2.47,1.65,1.65,2.47,3.84,2.47,6.56v1.51c-1-.29-2.31-.52-3.93-.7-1.61-.18-3.46-.27-5.54-.27-3.08,0-6.06.31-8.93.91-2.87.61-5.41,1.6-7.64,2.96-2.22,1.36-4,3.19-5.32,5.48-1.33,2.3-1.99,5.09-1.99,8.39s.57,6.34,1.72,8.71c1.15,2.37,2.8,4.28,4.95,5.75,2.15,1.47,4.71,2.53,7.69,3.17,2.97.65,6.29.97,9.95.97,5.81,0,10.75-.34,14.84-1.02,3.33-.55,6.06-1.05,8.2-1.49.8-.16,1.37-.87,1.37-1.68v-31.94c0-3.51-.39-6.7-1.18-9.57Zm-14.2,32.91c-.86.14-2.01.25-3.44.32-1.44.07-2.94.11-4.52.11-2.94,0-5.25-.48-6.94-1.45-1.69-.97-2.53-2.71-2.53-5.22,0-1.29.29-2.37.86-3.23.57-.86,1.36-1.54,2.37-2.04,1-.5,2.17-.84,3.49-1.02,1.33-.18,2.74-.27,4.25-.27,1.08,0,2.26.07,3.55.22,1.29.14,2.26.29,2.9.43v12.15Z"/>
                <path className="cls-3" d="m399.53,109.73c0,7.1-5.39,12.96-12.28,13.72-.86.09-1.52.83-1.52,1.7v12.63c0,.99.84,1.78,1.83,1.72,15.6-.95,28-13.94,28-29.77v-47.75c0-.95-.77-1.72-1.72-1.72h-12.59c-.95,0-1.72.77-1.72,1.72v47.75Z"/>
                <path className="cls-3" d="m365.18,85.5l-7.9-24.07c-.23-.71-.89-1.18-1.63-1.18h-14.7c-.71,0-1.35.44-1.61,1.11l-9.23,24.45-7.88-24.37c-.23-.71-.89-1.19-1.63-1.19h-13.23c-1.17,0-1.99,1.14-1.63,2.25l17.45,53.94c.23.71.89,1.19,1.63,1.19h9.23c.71,0,1.35-.44,1.61-1.11l12.14-32.17,10.38,32.09c.23.71.89,1.19,1.63,1.19h9.23c.71,0,1.35-.44,1.61-1.11l20.36-53.94c.42-1.12-.41-2.32-1.61-2.32h-13.46c-.71,0-1.35.44-1.61,1.11l-9.16,24.14Z"/>
                <path className="cls-3" d="m240.45,104.41c-1.83.32-3.6.48-5.32.48-3.59,0-5.97-1-7.15-3.01-1.18-2.01-1.77-4.69-1.77-8.07v-19.89h17.53c.95,0,1.72-.77,1.72-1.72v-9.9c0-.95-.77-1.72-1.72-1.72h-17.53v-14.65c0-1.06-.95-1.86-1.99-1.7l-12.59,2.03c-.83.13-1.44.85-1.44,1.7v46.07c0,3.66.34,6.99,1.02,10,.68,3.01,1.88,5.58,3.6,7.69,1.72,2.12,4.01,3.76,6.88,4.95,2.87,1.18,6.49,1.77,10.86,1.77,3.44,0,6.34-.29,8.71-.86,1.83-.44,3.53-.95,5.11-1.53.78-.28,1.24-1.1,1.09-1.92l-1.65-9.12c-.18-1-1.18-1.63-2.15-1.35s-2.07.54-3.2.74Z"/>
            </g>
        </svg>
    )
}

export default LogoIcon;