const Urls = {
    home: "/", // Home page
    about: "/apropos", // About page
    tree: "/tree", // Tree page
    gallery: "/galerie", // Gallery page
    events: "/evenements", // Events page
    contact: "/contact", // Contact page
    login: "/login", // Login page
    logout: "/logout", // Logout page
    notFound: "*", // Not found page
}

export default Urls;